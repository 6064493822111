import { disableVersionPowerRatios, enableVersionPowerRatios } from '@/api/aqversionpowerratio';

const disableVersionPowerRatio = {
	id: 'disableVersionPowerRatio',
	selectionType: 'multiple',
	label: 'aqversionpowerratio.actions.disableVersionPowerRatio',
	functionality: 'UPDATE_AQVERSIONPOWERRATIO',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('aqversionpowerratio.actions.disableVersionPowerRatio');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				aqversionpowerratioid: registries[i].aqversionpowerratioid
			});
		}

		const data = await disableVersionPowerRatios(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableVersionPowerRatio = {
	id: 'enableVersionPowerRatio',
	selectionType: 'multiple',
	label: 'aqversionpowerratio.actions.enableVersionPowerRatio',
	functionality: 'UPDATE_AQVERSIONPOWERRATIO',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('aqversionpowerratio.actions.enableVersionPowerRatio');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				aqversionpowerratioid: registries[i].aqversionpowerratioid
			});
		}

		const data = await enableVersionPowerRatios(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

export default {
	actions: [disableVersionPowerRatio, enableVersionPowerRatio]
};
